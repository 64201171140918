<template>
    <div class="user-list">
        <div class="empty-notice d-flex flex-column justify-content-center align-items-center flex-grow-1 mb-3"
             v-if="usersInDisplay.length === 0">
            <h1 class="display-2 text-center">🙀</h1>
            <h5 class="text-center mb-0">No user</h5>
        </div>
        <template v-else>
            <div class="flex-grow-1 h-100">
                <dynamic-scroller class="h-100"
                                  key-field="_id"
                                  v-bind:items="usersInDisplay"
                                  v-bind:min-item-size="120">
                    <template v-slot="{ item, index, active }">
                        <dynamic-scroller-item v-bind:item="item"
                                               v-bind:active="active"
                                               v-bind:size-dependencies="[item.name,]"
                                               v-bind:data-index="index">
                            <user-item v-bind:user="item"></user-item>
                        </dynamic-scroller-item>
                    </template>
                </dynamic-scroller>
            </div>
        </template>
    </div>
</template>

<script>
import { set, search, } from "@/workers/fuse.worker";
import { DynamicScroller, DynamicScrollerItem, } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
export default {
    name: "UserList",
    metaInfo () {
        return {
            "title": "Users",
        };
    },
    components: {
        DynamicScroller, DynamicScrollerItem,
        "UserItem": () => import("../../components/Users/UserItem"),
    },
    data () {
        return {
            usersInDisplay: [],
        };
    },
    computed: {
        users () {
            // TODO: users should be sorted by time created in descending order at server-side
            return this.$store.getters["users/itemsByRole"]("user").slice(0).reverse();
        },
        keyword () {
            return this.$route.query?.keyword ?? "";
        },
        status () {
            return this.$route.query?.status ?? "";
        },
        statusFilterUsers () {
            if (this.status) {
                if (this.status === "pending_verification") {
                    return this.users.filter(({ verified }) => verified === false);
                }
                return this.users.filter(({ approved }) => approved === (this.status === "approved"));
            }
            return this.users;
        },
    },
    methods: {

    },
    async mounted () {
        if (Array.isArray(this.users) && this.users.length > 0) {
            await set(this.users, { "keys": [ "countryCode", "phone", "name", ] });
        }
        if (this.keyword.length > 2) {
            this.usersInDisplay = await search(this.keyword);
        } else {
            this.usersInDisplay = this.statusFilterUsers;
        }
    },
    watch: {
        async statusFilterUsers (newVal) {
            if (Array.isArray(newVal) && newVal.length > 0) {
                await set(newVal, { "keys": [ "countryCode", "phone", "name", ] });
            }
            if (this.keyword.length > 2) {
                this.usersInDisplay = await search(this.keyword);
            } else {
                this.usersInDisplay = newVal;
            }
        },
        async keyword (newVal) {
            if (this.keyword.length > 2) {
                this.usersInDisplay = await search(newVal);
            } else {
                this.usersInDisplay = this.statusFilterUsers;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.user-list {
    height: calc(100% - 48.75px - 57.25px);
}
</style>
